.PhoneInput {
	border-radius: 8px;
	overflow: hidden;
}

.PhoneInput .PhoneInputCountry {
	padding: 8px;
	margin-right: unset;
}

.PhoneInput input[type='tel'] {
	border: none;
	outline: none;
	padding: 8px;
	font: inherit;
	background-color: transparent;
}

[dir='rtl'] .PhoneInput input[type='tel'] {
	direction: ltr;
	text-align: end;
}
