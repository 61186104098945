#__next {
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: stretch;
	min-height: 100dvh;
}

.floating-whatsapp .floating-whatsapp-button {
	bottom: 2.5rem;
}

.floating-whatsapp .floating-whatsapp-button::after {
	display: none !important;
}
